import { useEffect, useState, virtual } from 'haunted';
import { html } from 'lit-html';
import { Loading } from './loading.js';
import {
  isEventClick as isClick,
  isKeyEnterOrKeySpace as isEnterOrSpace
} from 'a11y-key-conjurer';

export const Haiku = virtual(() => {
  const [model, setModel] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);

  useEffect(() => {
    if (!model) {
      fetch('/assets/content/haikus.json')
        .then(response => {
          return response.json();
        })
        .then(data => {
          setTimeout(() => {
            setModel(data);
            setCurrentIndex(0);
          }, 1000);
        });
    }
  }, [model]);

  const haiku = model && model.items ? model.items[currentIndex] : null;

  function getNew(ev) {
    if ((isClick(ev) || isEnterOrSpace(ev)) && model && model.items) {
      const newIndex =
        currentIndex + 1 < model.items.length ? currentIndex + 1 : 0;
      setCurrentIndex(newIndex);
    }
  }

  return haiku
    ? html`
        <div class="haiku">
          <div class="haiku__body">
            <p class="haiku__content">
              ${haiku.content[0]}<br />
              ${haiku.content[1]}<br />
              ${haiku.content[2]}
            </p>
            <p class="haiku__cite">“${haiku.title}” by ${haiku.author}</p>
          </div>
          <div class="haiku__footer">
            <div
              tabindex="0"
              role="button"
              class="haiku__button"
              @click="${getNew}"
              @keydown="${getNew}"
            >
              Get new haiku
            </div>
          </div>
        </div>
      `
    : Loading();
});
